.align-center {
    align-items: center;
}

.flex {
    display: flex;
}

.ml-a {
    margin-left: auto;
}

.mr-a {
    margin-right: auto;
}

.pointer {
    cursor: pointer;
}

.text-end {
    text-align: end;
}

.wdh-100 {
    width: 100px !important;
}

.spinner {
    animation: spin 1s infinite linear;
}

.align-flex-end{
    align-items: flex-end;
}

@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

.justify-center {justify-content: center;}
.justify-end {justify-content: flex-end;}
.justify-start {justify-content: flex-start;}
.full-width {width : 100%;}
.pull-right {float : right;}
.dblock {display : block; width : 100%;}
.align-between {justify-content: space-between; align-items: center;}
.cursor {cursor : pointer;}
.cursor i {font-size: 20px;}
.alert i {margin-right : 20px;}
.margin-0 {margin: 0!important;}
.max-190 {max-width : 190px;}
.w300 {width: 300px;}
.inner-label {
    width: 100%;
    font-size: 8px;
    margin-bottom: 2px;
    text-transform: uppercase;
}
.close {
    color: #fff;
    text-shadow: none;
}

.form-horizontal .form-group.half i {
    left : 32px;
}
.form-horizontal select.form-control:not([size]):not([multiple]) {height: 46px !important;}

.material-switch.custom {
    margin-left: 30px;
}
.material-switch.custom, .material-switch.custom > label {
    margin-bottom: 0;
}
.material-switch.custom > label::before {
    height : 16px;
    width : 40px;
    margin-top: -13px;
}
.material-switch.custom > label::after {
    height : 22px;
    width : 22px;
    margin-top: -12px;
}

.material-switch.custom > input[type="checkbox"]:checked + label::after {
    left : 18px;
}

.filter-select {
    display: inline-block;
    margin-left: 0.5em;
}

/* logotype */
.logotype {
    display : block;
    width : 180px;
    height : 60px;
    overflow : hidden;
    text-align: center;
}
.logotype img {
    display : inline-block;
    max-height: 60px;
    margin-right: 0;
    object-fit: contain;
}

/* Animate dashboard button */
.btn_ {
    display: block;
    width: 100%;
    height: 233px;
    padding: 15px;
    margin-bottom: 20px;
    box-sizing: border-box;
    overflow: hidden;
}

.btn_ {
    position: relative;
    border-radius: 6px;
    transition: all 0.3s ease;
}
.btn_.btn-style-01 {
    border-top: 3px solid #fea911;
}
.btn_.btn-style-02 {
    border-top: 3px solid #18bad9;
}
.btn_.btn-style-03 {
    border-top: 3px solid #a36ad9;
}
.btn_.btn-style-04 {
    border-top: 3px solid #18bad9;
}

.btn_:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 70%;
    transition: all 0.3s ease;
}
.btn_:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 3px;
    height: 70%;
    transition: all 0.3s ease;
}

.btn_.btn-style-01:before {background: linear-gradient(#fea911, transparent);}
.btn_.btn-style-01:after {background: linear-gradient(#fea911, transparent);}

.btn_.btn-style-02:before {background: linear-gradient(#18bad9, transparent);}
.btn_.btn-style-02:after {background: linear-gradient(#18bad9, transparent);}

.btn_.btn-style-03:before {background: linear-gradient(#a36ad9, transparent);}
.btn_.btn-style-03:after {background: linear-gradient(#a36ad9, transparent);}

.btn_.btn-style-04:before {background: linear-gradient(#18bad9, transparent);}
.btn_.btn-style-04:after {background: linear-gradient(#18bad9, transparent);}

.btn_ .images {
    position: relative;
    float: left;
    width: 100%;
    height: 80px;
    padding-right: 0;
    margin-top: 10px;
    text-align: center;
    box-sizing: border-box;
    transition: all 0.3s ease;
}
.btn_ .images img {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-height: 100%;
    transition: all 0.3s ease;
}
.btn_ .title {
    float: left;
    display: block;
    width: 100%;
    margin-top: 42px;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    text-align: left;
    text-decoration: none;
}
.btn_.btn-style-01 .title {color: #fea911;}
.btn_.btn-style-02 .title {color: #18bad9;}
.btn_.btn-style-03 .title {color: #a36ad9;}
.btn_.btn-style-04 .title {color: #18bad9;}

.btn_ .description {
    float: left;
    display: block;
    width: 100%;
    margin-top: 15px;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    text-decoration: none;
    opacity: 0;
    white-space: normal;
    text-align: left;
    transition: all 0.3s ease;
}
.btn_.btn-style-01 .description {color: #fea911;}
.btn_.btn-style-02 .description {color: #18bad9;}
.btn_.btn-style-03 .description {color: #a36ad9;}
.btn_.btn-style-04 .description {color: #18bad9;}

.btn_:hover .images {
    height: 66px;
}
.btn_:hover .title {
    text-align: left;
}
.btn_:hover .description {
    opacity: 1;
}
.btn_:hover .images img {
    left: calc(100% - 85px);
    transform: translateX(0);
}

.btn_:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
}

.btn_:hover::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 3px;
    height: 100%;
}

.btn_.btn-style-01:hover::before {background: linear-gradient(#fea911, transparent);}
.btn_.btn-style-01:hover::after {background: linear-gradient(#fea911, transparent);}

.btn_.btn-style-02:hover::before {background: linear-gradient(#18bad9, transparent);}
.btn_.btn-style-02:hover::after {background: linear-gradient(#18bad9, transparent);}

.btn_.btn-style-03:hover::before {background: linear-gradient(#a36ad9, transparent);}
.btn_.btn-style-03:hover::after {background: linear-gradient(#a36ad9, transparent);}

.btn_.btn-style-04:hover::before {background: linear-gradient(#18bad9, transparent);}
.btn_.btn-style-04:hover::after {background: linear-gradient(#18bad9, transparent);}

/* button small icon */
.btn-small-icon {
    display : flex;
    width : 100%;
    height : 97px;
    padding : 5px;
    border-radius: 4px;
    border: 1px solid #868e96;
    background : transparent;
    transition: all 0.3s ease-out;
    align-items: center;
    justify-content: center;
    flex-flow: wrap row;
}
.btn-small-icon .icon_ {
    width : 100%;
    /*margin-bottom: 15px;*/
    text-align: center;
    transition: all 0.3s ease-out;
}
.btn-small-icon .fa {
    font-size: 30px;
}
.btn-small-icon .description {
    width : 100%;
    font-family: 'Montserrat', sans-serif;
    line-height : 14px;
    font-size: 12px;
    text-align: center;
    transition: all 0.3s ease-out;
}

.btn-small-icon:hover {
    border: 1px solid #868e96;
    background : #868e96;
}
.btn-small-icon:hover .fa {
    color : #fff;
}
.btn-small-icon:hover .description {
    color : #fff;
}

.margin-0 {margin: 0 10px 0 0!important;}
.fs-130 {font-size: 130%!important;}
.tab .nav-tabs li a.active {background: #367cff!important;color: #fff;}
.alert-content {width: 100%;padding: 20px;}
.alert-content.padd-bottom {width: 100%;padding: 0;}


/* ------------------ */
/* --------RWD------- */
/* ------------------ */

@media (max-width: 1023px) {

}

@media (min-width: 1024px) and (max-width: 1199px) {
    .ds-column-1 {order: 2;}
    .ds-column-2 {order: 2;}
    .ds-column-3 {order: 1;}

    .pr-w-50 {flex: 0 0 50%; max-width: 50%;}

    .fs-130 {font-size: 100%!important;}
    .tab .nav-tabs li a {padding: 10px;font-size: 12px;}
    .description-column {order: 2;flex: 0 0 100%;max-width: 100%;}
}

@media (min-width: 1200px) and (max-width: 1599px) {
    .ds-column-1 {order: 2; flex: 0 0 50%; max-width: 50%;}
    .ds-column-2 {order: 2; flex: 0 0 50%; max-width: 50%;}
    .ds-column-3 {order: 1; flex: 0 0 100%; max-width: 100%;}

    .ds-card-1 {flex: 0 0 25%;max-width: 25%;}
    .ds-card-2 {flex: 0 0 25%;max-width: 25%;}
    .ds-card-3 {flex: 0 0 25%;max-width: 25%;}
    .ds-card-4 {flex: 0 0 25%;max-width: 25%;}

    .pr-w-50 {flex: 0 0 40%; max-width: 40%;}

    .margin-0 {margin: 0 25px 0 0!important;}

    .login-column {flex: 0 0 40%;max-width: 40%;}
}

@media (min-width: 1600px) {
    .ds-card-1 {flex: 0 0 50%;max-width: 50%;}
    .ds-card-2 {flex: 0 0 50%;max-width: 50%;}
    .ds-card-3 {flex: 0 0 50%;max-width: 50%;}
    .ds-card-4 {flex: 0 0 50%;max-width: 50%;}

    .margin-0 {margin: 0 30px 0 0!important;}
}


.pos-rel {
    position: relative;
}

.loader {
    color: #ffffff;
    font-size: 90px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 72px auto;
    position: relative;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}
@keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}
@-webkit-keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


